import React from 'react';
import { setWebSiteJsonLd, useSeo } from '@bamboo/core-lib/repo/seo';

const WebsiteJsonLd = () => {
  const { webSiteJsonLd } = useSeo();

  const newWebSiteJsonLd = {
    '@id': `${process.env.ECOMMERCE_URL}/#website`,
    '@type': 'WebSite',
    url: `${process.env.ECOMMERCE_URL}`,
    name: 'Bamboostock',
    description: 'Banco de Imagens',
    publisher: {
      '@id': `${process.env.ECOMMERCE_URL}/#organization`,
    },
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${process.env.ECOMMERCE_URL}/search?search_text={search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      },
    ],
    inLanguage: 'pt-BR',
  };

  React.useEffect(() => {
    if (webSiteJsonLd === undefined) {
      setWebSiteJsonLd(newWebSiteJsonLd);
    }
  }, [webSiteJsonLd]);

  if (typeof window === 'undefined') {
    setWebSiteJsonLd(newWebSiteJsonLd);
  }

  return null;
};

export default WebsiteJsonLd;
