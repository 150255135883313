import { styled } from '@mui/material/styles';
import DefaultLogo from '@bamboo/core-lib/components/Display/DefaultLogo';

export const NavHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '70px',
  borderBottom: '1px solid #f2f2f2',
  background: '#fff',
  padding: '10px 30px 10px',
  zIndex: '999',
  position: 'fixed',
  top: 0,
  width: 'calc(100vw - 59px)',
  height: 'calc(76px - 20px)',
  maxWidth: '100vw',

  [theme.breakpoints.down('md')]: {
    width: 'calc(100vw - 40px)',
    padding: '10px 20px',
  },
}));

export const StyledLogo = styled(DefaultLogo)(({ theme }) => ({
  maxWidth: '173px',
  minWidth: '173px',
  maxHeight: '25px',
  cursor: 'pointer',
  marginTop: '-8px',

  ['@media (max-width:1400px)']: {
    minWidth: '95px',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '-5px',
    maxWidth: '130px',
  },
}));

export const StyledButton = styled('button')(() => ({
  color: '#fff',
  backgroundColor: '#000',
  borderRadius: '15px',
  boxSizing: 'border-box',
  outline: 0,
  margin: 0,
  border: 0,
  fontSize: '10px',
  padding: '6px 16px',
}));

export const SideNavigationContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

export const InputContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '4px',
  padding: '6px 16px',
  width: '100%',
  maxWidth: '300px',
  borderRadius: '999px',
  background: '#fff',
  border: '1px solid gainsboro',
  marginRight: '40px',
}));

export const StyledInput = styled('input')(() => ({
  border: '0px',
  borderRadius: '0px',
  outline: 'none',
  width: '100%',
  fontsize: '12px',
  fontWeight: '300',
  color: 'black',
  '::placeholder': {
    color: 'rgb(117, 117, 117)',
    fontsize: '12px',
    fontWeight: '300',
  },
  '&:focus': {
    borderColor: 'black !important',
  },
}));
