import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Button, DialogContent, Box, Typography } from '@mui/material';
import api from '@bamboo/core-lib/api';
import { useInlineNotification } from '@bamboo/core-lib/components/Notification';
import type {
  LoginFormProps,
  LoginFormValues,
} from '@bamboo/core-lib/components/Auth/LoginForm';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { OnboardingForm } from '@/features/auth/components/OnboardingForm';
import type {
  OnboardingFormProps,
  OnboardingFormValues,
} from '@bamboo/core-lib/components/Auth/OnboardingForm';
import SocialLogin from '@/components/layout/SocialLogin';
import { ApplicationID } from '@bamboo/ts-utils';
import Link from 'next/link';
import { SubmittedType } from '@/features/auth/screens/LoginPage';
import LoginForm from '@bamboo/core-lib/components/Auth/LoginForm';
import router from 'next/router';
import { t } from 'i18next';

const LoginFormContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '445px',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '32px',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  padding: '100px 50px 50px 50px',
  backgroundColor: 'rgb(251, 251, 251)',
  [theme.breakpoints.down('lg')]: {
    padding: '70px 20px 20px 20px',
    marginTop: '50px',
    maxWidth: '100%',
  },
}));

const FormTitle = styled('h2')(() => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  color: '#000000',
  fontSize: '24px',
  fontWeight: 600,
}));

const FormDescription = styled('p')(() => ({
  marginTop: '0px',
  marginBottom: '12px',
  boxSizing: 'border-box',
  color: 'rgb(62, 62, 62)',
  fontSize: '12px',
  fontWeight: 400,
}));

const CloseContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1,
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '20px 20px 0',
  opacity: 1,
}));

const DefaultLabel = {
  loginText: 'Faça seu login',
  onboardingText: 'Crie sua conta',
  onboardingDescription: 'Ainda não tem uma conta?',
  close: 'Fechar',
  noAccount: 'Ainda não tem uma conta?',
  hasAccount: 'Já tem uma conta?',
  create_account: 'Criar conta',
  generic_login_error: 'Falha ao realizar login',
  user_already_exists: 'Já existe um usuário com este e-mail',
  generic_onboarding_error: 'Falha ao criar conta',
  user_doesnt_exist: 'Usuário não encontrado',
  loginDescription:
    'Se você já faz parte da família, acesse com uma das opções abaixo:',
  loginFormLabels: undefined as LoginFormProps['labels'],
  onboardingFormLabels: undefined as OnboardingFormProps['labels'],
  social_continue_with: 'Continuar com',
  signing_method_or: 'Ou',
};

export type LoginDialogProps = {
  open: boolean;
  close: (logged: boolean) => void;
  labels?: typeof DefaultLabel;
};

const LoginDialog = (props: LoginDialogProps) => {
  const { open, close, labels = DefaultLabel } = props;

  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  const [Notification, notify] = useInlineNotification();
  const [NotificationSocial, notifySocial] = useInlineNotification();
  const [submittedForm, setSubmittedForm] = React.useState<SubmittedType>();

  useEffect(() => {
    if (router.isReady) {
      if (((router.query.oauthError as string)?.trim?.() ?? '').length > 0) {
        notifySocial({
          message: `${t('auth:error.social_login_error')}: ${
            router.query.oauthError ?? ''
          }`,
          type: 'error',
          temporary: true,
        });
        return;
      }
      if (((router.query.token as string)?.trim?.() ?? '').length > 0) {
        router.push('/search');
        return;
      }
    }
  }, [notifySocial]);

  const handleSubmit = React.useCallback((formValues: LoginFormValues) => {
    const { username, password, rememberme } = formValues;
    setSubmittedForm(SubmittedType.login);
    return api
      .post(
        '/api/auth/signin',
        {
          applID: ApplicationID.ecommerce,
          email: username,
          password,
          rememberMe: rememberme,
          deviceID: '',
          deviceName: '',
          ip: '',
          location: '',
          coord: '',
        },
        { withCredentials: true }
      )
      .then(() => {
        router.push('/home');
      })
      .catch((e) => {
        let messagem = t('navigation:authForm.generic_login_error');
        const err = e?.response?.data?.error?.message ?? e?.message ?? e;
        if (err?.indexOf?.('No user with this email') > -1) {
          messagem = t('navigation:authForm.user_doesnt_exist');
        }

        notify({
          message: messagem,
          type: 'error',
          temporary: true,
        });
      });
  }, []);

  const handleCreate = React.useCallback((formValues: OnboardingFormValues) => {
    const { userFirstName, userLastName, password, email } = formValues;
    setSubmittedForm(SubmittedType.onboarding);

    return api
      .authCreateAccount({
        name: userFirstName + ' ' + userLastName,
        applID: ApplicationID.ecommerce,
        email: email,
        password,
        deviceID: '',
        deviceName: '',
        ip: '',
        location: '',
        coord: '',
      })
      .then(() => {
        router.push('/home');
      })
      .catch((e) => {
        let message = t('navigation:authForm.generic_onboarding_error');
        const err = e?.response?.data?.error?.message ?? e?.message ?? e;
        if (err?.indexOf?.('Already exists user') > -1) {
          message = t('navigation:authForm.user_already_exists');
        }

        notify({
          message: message,
          type: 'error',
          temporary: true,
        });
      });
  }, []);

  const [page, setPage] = useState<string>('login');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      style={{
        width: '100%',
      }}
      PaperProps={{
        style: {
          borderRadius: '32px',
          position: 'relative',
          maxWidth: '100%',
          height: 'auto',
        },
      }}
    >
      <CloseContainer
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: '99',
        }}
      >
        <Button
          onClick={handleClose}
          color="inherit"
          style={{
            display: 'inline-block',
            letterSpacing: '1px',
            fontSize: 'inherit',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: 1,
            textTransform: 'none',
            marginTop: '0px',
            marginBottom: '15px',
          }}
        >
          {labels?.close}
        </Button>
      </CloseContainer>
      <DialogContent
        style={{
          padding: '0px',
        }}
      >
        {page === 'login' && (
          <LoginFormContainer>
            <div style={{ marginBottom: '10px' }}>
              <FormTitle>Que bom ter você de volta!</FormTitle>
            </div>
            <div>
              <FormDescription>
                Se você já faz parte da família Bamboo, acesse sua conta.
              </FormDescription>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <SocialLogin
                labels={{
                  google: ['Fazer login com o ', 'Google'],
                }}
                urls={{ google: process.env.OAUTH_LOGIN_URL! }}
              />
              <NotificationSocial />
            </div>
            <div
              style={{
                marginTop: '18px',
                marginBottom: '18px',
                textAlign: 'center',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 'calc(50% - 1px)',
                  background: '#544F4F1F',
                  height: '1px',
                  width: '100%',
                }}
              ></div>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  background: '#fbfbfb',
                  position: 'relative',
                  zIndex: 9,
                  display: 'inline',
                  padding: '8px',
                }}
              >
                Ou faça login com o seu e-mail
              </Typography>
            </div>
            <LoginForm onSubmit={handleSubmit}>
              <HiddenComponent hidden={submittedForm !== SubmittedType.login}>
                <Box sx={{ mt: 3 }}>
                  <Notification />
                </Box>
              </HiddenComponent>
            </LoginForm>
            <div style={{ textAlign: 'left' }}>
              <Typography
                component={Link}
                href={'/auth/password-recover'}
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  padding: '0px',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: '20px',
                  color: '#544F4F',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Esqueceu sua senha?
              </Typography>
            </div>

            <div
              style={{
                marginTop: '48px',
                marginBottom: '16px',
                background: '#544F4F1F',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                component={'span'}
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  padding: '0px',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: '20px',
                  color: '#544F4FBF',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                Não tem conta Bamboo ainda?{' '}
              </Typography>
              <Typography
                component={'span'}
                onClick={() => setPage('register')}
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  padding: '0px',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: '20px',
                  color: '#07BABC',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Cadastre-se
              </Typography>
            </div>
          </LoginFormContainer>
        )}

        {page === 'register' && (
          <LoginFormContainer>
            <div style={{ marginBottom: '10px' }}>
              <FormTitle>Crie sua conta</FormTitle>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <FormDescription>
                Faça parte da familía Bamboo, um banco de vídeos verdadeiramente
                Brasileiro.
              </FormDescription>
            </div>
            <OnboardingForm onSubmit={handleCreate}>
              <HiddenComponent
                hidden={submittedForm !== SubmittedType.onboarding}
              >
                <Box sx={{ mt: 3 }}>
                  <Notification />
                </Box>
              </HiddenComponent>
            </OnboardingForm>

            <div
              style={{
                marginTop: '48px',
                marginBottom: '16px',
                background: '#544F4F1F',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                component={'span'}
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  padding: '0px',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: '20px',
                  color: '#544F4FBF',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                Já tem uma conta Bamboo?{' '}
              </Typography>
              <Typography
                component={'span'}
                onClick={() => setPage('login')}
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  padding: '0px',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: '20px',
                  color: '#07BABC',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Entrar
              </Typography>
            </div>
          </LoginFormContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
