import React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseRounded } from '@mui/icons-material';
import { formatBRL } from '@bamboo/ts-utils';
import { calculateItemPrice } from '@bamboo/core-lib/src/api/flows/cart';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import VideoDownloadButton from '@/components/layout/VideoDownloadButton';
import Link from 'next/link';
import type { Cart } from '@bamboo/core-lib/src/api/types';
import type { IconButtonProps } from '@mui/material';
import type { VideoDownloadButtonProps } from '@/components/layout/VideoDownloadButton';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';

const Root = styled('div')(() => ({
  boxSizing: 'border-box',
}));

const Table = styled('table')(() => ({
  backgroundColor: 'transparent',
  fontSize: '0.9em',
  boxSizing: 'border-box',
  width: '100%',
  padding: '0px',
  tableLayout: 'fixed',
}));

const TD = styled('td')(() => ({
  lineHeight: 1.5,
  verticalAlign: 'top',
  boxSizing: 'border-box',
  border: '0px none',
  position: 'relative',
  backgroundColor: 'white',
  padding: '0px',
}));

const LinkContainer = styled(Link)(() => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
}));
const FocusedItemContainer = styled('div')(() => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
}));

const Image = styled('img')(() => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'medium none',
  borderRadius: '7px',
  boxShadow: 'none',
  objectFit: 'cover',
  width: '64px',
  height: '56px',
  overFlowClipMargin: 'content-box',
  overflow: 'clip',
}));

const DescriptionContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  gridColumn: '2 / 4',
  margin: '0px',
  paddingLeft: '20px',
  color: 'rgb(39, 39, 39)',
  fontSize: '10px',
  fontWeight: 600,
}));

const SKU = styled(
  (props: React.HTMLProps<HTMLParagraphElement> & { small?: boolean }) => {
    const { small, ...others } = props;
    return <p {...others} />;
  }
)(({ theme, small }) => ({
  boxSizing: 'border-box',
  marginTop: '0px',
  marginBottom: '-2px',
  ...(!small
    ? {
        fontSize: '10px',
        fontWeight: 600,
        textDecorationThickness: '0px',
      }
    : {}),
}));

const Title = styled(
  (props: React.HTMLProps<HTMLParagraphElement> & { small?: boolean }) => {
    const { small, ...others } = props;
    return <p {...others} />;
  }
)(({ small, theme }) => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  marginTop: '0px',
  marginBottom: '5px',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  paddingBottom: '10px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  textDecoration: 'none',
  fontSize: '14px',
  color: 'rgb(41, 41, 41)',
  fontWeight: 600,
  ...(small
    ? {
        display: 'none',
      }
    : {}),
}));

const Info = styled('p')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  fontWeight: 400,
  marginInlineStart: '0px',
  marginTop: '0px',
  marginBottom: '-2px',
}));

const Currency = styled(
  (props: React.HTMLProps<HTMLElement> & { small?: boolean }) => {
    const { small, ...others } = props;
    return <span {...others} />;
  }
)(({ small, theme }) =>
  !small
    ? {
        boxSizing: 'border-box',
        fontSize: '11px',
        color: 'rgb(49, 49, 49)',
        paddingRight: '3px',
        fontWeight: 500,
      }
    : {}
);

const Price = styled(
  (props: React.HTMLProps<HTMLElement> & { small?: boolean }) => {
    const { small, ...others } = props;
    return <span {...others} />;
  }
)(({ small, theme }) =>
  small
    ? {
        boxSizing: 'border-box',
        right: '10px',
        fontSize: '15px',
        fontWeight: 500,
        color: 'rgb(25, 186, 187)',
        position: 'relative',
      }
    : {
        boxSizing: 'border-box',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'rgb(33, 33, 33)',
      }
);

const CloseButton = styled((props: IconButtonProps & { small?: boolean }) => {
  const { small, ...others } = props;
  return <IconButton {...others} />;
})(({ theme, small }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  padding: '0px',
  color: 'rgb(255, 255, 255, 50%)',
  ...(!small
    ? {
        top: '5px',
        left: '5px',
        color: 'grey',
        backgroundColor: theme.palette.common.white,
      }
    : {}),
}));

export type ProductItemsProps = {
  items: Cart['cartProducts'];
  small?: boolean;
  disableRemoval?: boolean;
  downloadLinks?: {
    [caprID: string]: { usdoID: number; url: string } | undefined;
  };
  onRemove?: (caprID: number) => void;
  labels?: {
    download?: string;
  } & VideoDownloadButtonProps['labels'];
};

const ItemContainer = ({
  href,
  children,
  currentRoutePath,
}: {
  href: string;
  currentRoutePath: string;
  children: React.ReactNode;
}) => {
  if (href == currentRoutePath)
    return <FocusedItemContainer>{children}</FocusedItemContainer>;

  return <LinkContainer href={href}>{children}</LinkContainer>;
};

const ProductItems = (props: ProductItemsProps) => {
  const { items, small, disableRemoval, downloadLinks, labels, onRemove } =
    props;
  const theme = useTheme();
  const router = useRouter();

  return (
    <Root>
      {items.map((item) => {
        const price = calculateItemPrice(item.price, item.variations);
        const downloadLink = downloadLinks?.[item?.caprID];
        const openDownload = !!downloadLink && !small;
        const hiddenClose = !onRemove || disableRemoval || openDownload;

        return (
          <Table key={item.caprID}>
            <ItemContainer
              href={`/product/${item.slug}`}
              currentRoutePath={router.asPath}
            >
              <tbody style={{ boxSizing: 'border-box' }}>
                <tr style={{ boxSizing: 'border-box' }}>
                  <TD>
                    <Image src={item.thumbnailImageUrl} />
                    <HiddenComponent hidden={hiddenClose}>
                      <CloseButton
                        small={small}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRemove?.(item.caprID);
                        }}
                      >
                        <CloseRounded
                          sx={{
                            fontSize: 'small',
                            [theme.breakpoints.down('md')]: {
                              fontSize: '16px',
                              marginTop: '2px',
                              marginLeft: '2px',
                            },
                          }}
                        />
                      </CloseButton>
                    </HiddenComponent>
                  </TD>
                  <TD style={{ width: '100%' }}>
                    <DescriptionContainer>
                      <SKU>{`ID ${item.sku}`}</SKU>
                      <Title small={small}>{item.title}</Title>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {item.variations
                            .sort((a, b) => (a.povaID > b.povaID ? 1 : -1))
                            .map((va) => (
                              <Info key={va.povaID}>
                                {va.name == 'HD' || va.name == '4K'
                                  ? null
                                  : va.name}
                              </Info>
                            ))}
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                              alignSelf: 'center',
                              fontWeight: 400,
                            }}
                          >
                            {item.license
                              .map((license: string) => license)
                              .join(', ')}
                          </span>
                        </div>
                        <HiddenComponent hidden={!openDownload}>
                          <VideoDownloadButton
                            downloadUrl={downloadLink?.url ?? ''}
                            usdoID={downloadLink?.usdoID ?? NaN}
                            sku={item.sku}
                            labels={labels}
                          >
                            {labels?.download}
                          </VideoDownloadButton>
                        </HiddenComponent>
                      </div>
                    </DescriptionContainer>
                  </TD>
                </tr>
              </tbody>
            </ItemContainer>
          </Table>
        );
      })}
    </Root>
  );
};

export default ProductItems;
