import { styled } from '@mui/material/styles';
import Link, { type LinkProps } from 'next/link';

export const NavListContainer = styled('nav')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '35px',
}));

export const StyledLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '7.5px',
  textDecoration: 'none',
  color: '#414141',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '13.41px',
  whiteSpace: 'nowrap',
  width: 'fit-content',
  borderBottom: '2px solid #fff',
  transition: 'all 0.3s ease 0s',

  '&:hover': {
    borderBottom: '2px solid #414141',
  },
}));

type Props = LinkProps & {
  selected?: boolean;
};

export const NavLink = styled(Link)<Props>(({ selected }) => ({
  textDecoration: 'none',
  fontSize: '13px',
  color: '#414141',
  fontWeight: 300,
  whiteSpace: 'nowrap',
  transition: 'all 0.3s ease 0s',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px',

  svg: {
    width: '1em',
    height: '1em',
    fill: '#7F7F7F',
  },
}));

export const ActionContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginLeft: 'auto',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',
}));
