import * as React from 'react';

function Paper(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ ...props }}
    >
      <g clip-path="url(#clip0_630_45917)">
        <path
          d="M26 22.8281V1.82812H3V28.8281C3 30.4851 4.343 31.8281 6 31.8281H8"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 28.8281C9 30.4851 7.657 31.8281 6 31.8281H27C28.657 31.8281 30 30.4851 30 28.8281V26.8281H9V28.8281Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 7.82812H20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 13.8281H20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 19.8281H20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_45917">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.828125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Paper;
