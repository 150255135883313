import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(() => ({
  borderRadius: '42px',
  width: '100%',
  maxWidth: '80px',
  height: '40px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '13px',
  color: '#414141',

  transition: 'all 0.3s ease 0s',

  '&:hover': {
    opacity: 0.8,
  },
}));

export const StyledRegisterButton = styled(Button)(({ theme }) => ({
  background: '#000',
  color: '#fff',
  borderRadius: '42px',
  width: '100%',
  maxWidth: '173px',
  minWidth: '150px',
  height: '40px',
  maxHeight: '42px',
  gap: '10px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '13.41px',
  boxShadow: 'none',

  transition: 'all 0.3s ease 0s',

  '&:hover': {
    background: '#000',
    opacity: 0.8,
  },

  [theme.breakpoints.down('lg')]: {
    p: {
      display: 'none',
    },
  },
}));
