import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Search } from '@mui/icons-material';
import { IconButton, AppBar, useMediaQuery } from '@mui/material';
import Logo from '@bamboo/core-lib/components/Display/DefaultLogo';
import { ClipsSearchQueryOptions } from '@bamboo/core-lib/src/repo/clips';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import { toQueryString } from '@bamboo/ts-utils';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { navigationConfig } from './navigation.config';
import FavoriteIconButton from '@/components/layout/FavoriteIconButton';
import CartIconButton from '@/components/layout/CartIconButton';
import ProfileIconButton from '@/components/layout/ProfileIconButton';
import SideNavigation from '@/components/layout/TobarSideNavigation';
import { useTopBarMenu } from '@/lib/hooks/menu';
import Icons from '@bamboo/core-lib/components/Icons';

const TopSection = styled('section')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  zIndex: 123,
  backgroundColor: 'rgb(255, 255, 255)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Container = styled('section')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  padding: '3px 20px 4px 0px',
  zIndex: 123,
  maxHeight: '6vh',
  minHeight: '60px',
  backgroundColor: 'rgb(255, 255, 255)',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px',
    borderColor: 'rgb(236, 236, 236)',
  },
}));

const Toolbar = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  MozBoxAlign: 'center',
  alignItems: 'center',
  maxHeight: '6vh',
  minHeight: '60px',
}));

const SiteNavContainer = styled('div')(() => ({
  flex: 1,
  marginTop: '5px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
}));

const LinkContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '18%',
  minWidth: '170px',
  padding: '0% 2%',
}));

const LinkItem = styled(Link)(() => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
  display: 'inline-block',
}));

const ListButtonsContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
}));

const List = styled('ul')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 2,
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 'normal',
  display: 'flex',
  marginLeft: '10px',
}));

const ListItem = styled('li')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  display: 'block',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 'normal',
  position: 'relative',
  borderWidth: '0px',
  // marginRight: '11px',
}));

const NavLink = styled(
  (props: LinkProps & { selected?: boolean; children: React.ReactNode }) => {
    const { selected, ...other } = props;
    return <Link {...other} />;
  }
)(({ selected }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  position: 'relative',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  lineHeight: '20px',
  textDecoration: 'none',
  transition: 'all 0.4s ease 0s',
  padding: '13px 20px',
  whiteSpace: 'nowrap',
  fontSize: '11px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: 'rgb(33, 33, 33)',
  fill: 'rgb(33, 33, 33)',
  paddingTop: '4px',
  paddingBottom: '4px',
  borderBottom: '0px none',
  '&: hover': {
    fill: '#FF9966',
    color: '#FF9966',
    '&:after': {
      position: 'absolute',
      content: "''",
      backgroundColor: '#363636',
      height: '2px',
      bottom: 0,
      zIndex: 2,
      width: 'calc(100% - 30px) !important',
      left: '15px !important',
    },
  },
  ...(selected
    ? {
        fill: '#FF9966',
        color: '#FF9966',
        '&:after': {
          position: 'absolute',
          content: "''",
          backgroundColor: '#363636',
          height: '2px',
          bottom: 0,
          zIndex: 2,
          width: 'calc(100% - 30px) !important',
          left: '15px !important',
        },
      }
    : {}),
}));

const InputContainer = styled('li')(() => ({
  position: 'relative',
  display: 'flex',
  width: '15%',
  minWidth: '190px',
  marginRight: '43px',
}));

const InputStyled = styled('input')(() => ({
  margin: '0px',
  lineHeight: 1.5,
  overflow: 'visible',
  appearance: 'textfield',
  outlineOffset: '-2px',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease 0s',
  width: '100%',
  borderRadius: '100px',
  border: '1px solid gainsboro',
  right: '0px',
  marginTop: '7px',
  fontSize: '12px',
  padding: '8px 8px 8px 18px',
  '&:focus': {
    borderColor: '#333',
    outline: '0 none',
  },
}));

const SearchButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '10px',
  bottom: '0px',
  top: '7px',
  transition: 'all 0.3s ease 0s',
  color: '#7f7f7f',
}));

const IconsContainer = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '5%',
  minWidth: '100px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const ActionsContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  margin: '12px 0px 0px',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    margin: 0,
    justifyContent: 'flex-end',
    '& > *:nth-child(n+2)': {
      marginLeft: '10px',
    },
  },
}));

const LogoStyled = styled(Logo)(({ theme }) => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'medium none',
  borderRadius: '0px',
  height: 'auto',
  boxShadow: 'none',
  verticalAlign: 'middle',
  display: 'inline-block',
  width: '100%',
  maxWidth: '100%',
  margin: '-2px',
  minWidth: '215px',
  [theme.breakpoints.down('xl')]: {
    minWidth: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    width: '170px',
    margin: 0,
  },
}));

const AppTopBar = () => {
  const { t } = useTranslation(navigationConfig.i18nNamespaces);
  const [search, setSearch] = React.useState('');
  const [isMD, setIsMD] = React.useState(true);
  const [menu] = useTopBarMenu();
  const router = useRouter();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'), {
    noSsr: true,
  });

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      e?.stopPropagation?.();
      router.push(
        `/search/${toQueryString({
          [ClipsSearchQueryOptions.SearchText]: search || undefined,
        })}`
      );
    },
    [search, router]
  );

  const handleChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  React.useEffect(() => {
    setIsMD(isMediumScreen);
  }, [isMediumScreen]);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ background: 'none', zIndex: '115' }}
    >
      <TopSection>
        <div
          style={{
            display: 'flex',
            textAlign: 'right',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <span
            style={{
              display: 'flex',
              direction: 'ltr',
              borderTop: '4.8px solid #000',
              margin: '0px 0px 0px auto',
              borderRadius: '25px',
              width: '82%',
            }}
          />
        </div>
      </TopSection>
      <Container>
        <Toolbar>
          <LinkContainer>
            <LinkItem href="/">
              <LogoStyled />
            </LinkItem>
          </LinkContainer>
          <HiddenComponent hidden={isMD}>
            <SiteNavContainer>
              <ListButtonsContainer>
                <List>
                  {menu.map((opt, i) => (
                    <ListItem key={i}>
                      <NavLink
                        href={opt.to}
                        selected={router.pathname === opt.to}
                      >
                        {opt.title}
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              </ListButtonsContainer>
            </SiteNavContainer>
            <InputContainer>
              <div
                style={{
                  boxSizing: 'border-box',
                  position: 'relative',
                  width: '100%',
                  flexWrap: 'wrap',
                  display: 'flex',
                  marginRight: '10px',
                  justifyContent: 'flex-end',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <form style={{ flex: 1 }} onSubmit={handleSubmit}>
                  <InputStyled
                    name="_products_search"
                    placeholder={t('common:actions.search')}
                    onChange={handleChangeText}
                  />
                  <SearchButton type="submit">
                    <Search color="inherit" style={{ width: 20, height: 20 }} />
                  </SearchButton>
                </form>
              </div>
            </InputContainer>
          </HiddenComponent>
          <IconsContainer>
            <ActionsContainer>
              <HiddenComponent hidden={isMD}>
                <ProfileIconButton
                  labels={{
                    logoutDialog: {
                      accept: t('common:actions.yes'),
                      refuse: t('common:actions.no'),
                      text: t('common:logoutText'),
                    },
                  }}
                />
                <FavoriteIconButton
                  dialogLabels={{
                    close: t('common:actions.close'),
                    loginText: t('navigation:authForm.title'),
                    onboardingDescription: t(
                      'navigation:authForm.onboarding_description'
                    ),
                    generic_login_error: t(
                      'navigation:authForm.generic_login_error'
                    ),
                    user_already_exists: t(
                      'navigation:authForm.user_already_exists'
                    ),
                    generic_onboarding_error: t(
                      'navigation:authForm.generic_onboarding_error'
                    ),
                    user_doesnt_exist: t(
                      'navigation:authForm.user_doesnt_exist'
                    ),

                    noAccount: t('navigation:authForm.no_account'),
                    hasAccount: t('navigation:authForm.has_account'),
                    create_account: t('navigation:authForm.create_account'),
                    onboardingText: t('navigation:authForm.onboarding_title'),
                    loginDescription: t('navigation:authForm.description'),
                    loginFormLabels: {
                      email_username: t('navigation:authForm.email_username'),
                      remember_me: t('navigation:authForm.remember_me'),
                      forgot_password: t('navigation:authForm.forgot_password'),
                      signin_submit: t('navigation:authForm.signin_submit'),
                      password: t('navigation:authForm.password'),
                    },
                    onboardingFormLabels: {
                      email: t('navigation:authForm.email_username'),
                      password: t('navigation:authForm.password'),
                      password_confirm: t(
                        'navigation:authForm.onboarding_confirm_password'
                      ),
                      user_first_name: t(
                        'navigation:authForm.onboarding_user_name'
                      ),
                      user_last_name: t(
                        'navigation:authForm.onboarding_user_name'
                      ),
                      reading_terms: t(
                        'navigation:authForm.onboarding_reading_terms'
                      ),
                      terms: t('navigation:authForm.onboarding_terms'),
                      onboarding_submit: t(
                        'navigation:authForm.onboarding_submit'
                      ),
                      password_error: t(
                        'navigation:authForm.onboarding_password_error'
                      ),
                      email_error: t(
                        'navigation:authForm.onboarding_email_error'
                      ),
                    },
                    social_continue_with: t('auth:social.continue_with'),
                    signing_method_or: t('auth:form.signing_method_or'),
                  }}
                />
                <CartIconButton
                  labels={{
                    cartItens: (n) => t('common:cart.cartItens', { n }),
                    checkout: t('common:actions.checkout'),
                    total: t('common:cart.total'),
                    emptyCart: t('common:cart.emptyCart'),
                    search: t('common:cart.search'),
                    itemRemovedFromCart: t('common:cart.itemRemovedFromCart'),
                    failedToRemoveItemFromCart: t(
                      'common:cart.failedToRemoveItemFromCart'
                    ),
                  }}
                />
              </HiddenComponent>

              <HiddenComponent hidden={!isMD}>
                <SideNavigation color="#1a1a1a" icon={<Icons.MenuBold />} />
              </HiddenComponent>
            </ActionsContainer>
          </IconsContainer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default React.memo(AppTopBar);
