import React from 'react';
import { api } from '@bamboo/core-lib/src/api';
import {
  SettingsKeys,
  getLocalConfig,
} from '@bamboo/ui-lib/src/hooks/localConfig';
import { useCart, setCart } from '@bamboo/core-lib/src/repo/cart';

export const useCurrentCart = () => {
  const [cartID, setCartID] = React.useState('');
  const [loaded, setLoaded] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const { cartByID } = useCart();
  const carts = Object.keys(cartByID).length;
  const cart = cartByID[cartID];

  React.useEffect(() => {
    const config = getLocalConfig(SettingsKeys.cartID);
    const cartID = config?.value ?? '';
    setCartID(cartID);
  }, [carts]);

  React.useEffect(() => {
    if (!cart && !!cartID) {
      api
        .cartGet({ params: { cartID } })
        .then((r) => {
          setCart(r.data.item);
          setLoaded(true);
        })
        .catch((e) => {
          setLoaded(false);
          console.warn(e);
        })
        .finally(() => setLoading(false));
    } else {
      setLoaded(false);
      setLoading(false);
    }
  }, [cartID, cart]);

  return [cart, loading, cartID, loaded] as [
    typeof cart,
    typeof loading,
    typeof cartID,
    typeof loaded
  ];
};
