import React from 'react';
import { setOrganizationJsonLd } from '@bamboo/core-lib/repo/seo';

const OrganizationJsonLd = () => {
  const newOrganizationJsonLd = {
    '@id': `${process.env.ECOMMERCE_URL}/#organization`,
    '@type': 'Organization',
    name: 'Bamboostock',
    url: `${process.env.ECOMMERCE_URL}`,
    sameAs: [
      'https://www.facebook.com',
      process.env.INSTAGRAM_URL ?? 'https://www.instagram.com',
    ],
    logo: {
      '@type': 'ImageObject',
      '@id': `${process.env.ECOMMERCE_URL}/#logo`,
      inLanguage: 'pt-BR',
      url: `${process.env.ECOMMERCE_URL}/images/logo.png`,
      contentUrl: `${process.env.ECOMMERCE_URL}/images/logo.png`,
      width: 3062,
      height: 471,
      caption: 'Bamboostock',
    },
    image: {
      '@id': `${process.env.ECOMMERCE_URL}/#logo`,
    },
  };
  React.useEffect(() => {
    setOrganizationJsonLd(newOrganizationJsonLd);
  }, []);

  if (typeof window === 'undefined') {
    setOrganizationJsonLd(newOrganizationJsonLd);
  }

  return null;
};

export default OrganizationJsonLd;
