import { JsonLd } from './JsonLd';
import { useSeo } from '@bamboo/core-lib/repo/seo';

const JsonLdBuilder = () => {
  const {
    breadCrumbListJsonLd,
    imageObjectJsonLd,
    organizationJsonLd,
    webPageJsonLd,
    webSiteJsonLd,
    personJsonLd,
  } = useSeo();

  return (
    <JsonLd
      scriptKey="Application"
      dataArray={[
        breadCrumbListJsonLd,
        imageObjectJsonLd,
        organizationJsonLd,
        webPageJsonLd,
        webSiteJsonLd,
        personJsonLd,
      ].filter((item) => item !== undefined)}
    />
  );
};

export default JsonLdBuilder;
