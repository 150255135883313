import React from 'react';
import { styled } from '@mui/material/styles';
import { PowerSettingsNewRounded } from '@mui/icons-material';
import { Popover, IconButton, MenuItem, useMediaQuery } from '@mui/material';
import LabelIconButton from '@bamboo/core-lib/components/LabelIconButton';
import Link from 'next/link';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import LogoutDialog from '@/components/layout/LogoutDialog';
import { useUserMenu } from '@/lib/hooks/menu';
import type { SessionUser } from '@bamboo/core-lib/repo/session';
import type { LogoutDialogProps } from '@/components/layout/LogoutDialog';
import type { LabelIconButtonProps } from '@bamboo/core-lib/components/LabelIconButton';
import User from '@bamboo/core-lib/components/Icons/User';
import defaultAvatar from '@/public/images/defaultAvatar.jpeg';
import { useLoginModalStore } from '@/features/auth/components/loginModal/store';

const AvatarContainer = styled(IconButton)(() => ({
  boxSizing: 'border-box',
  width: 'fit-content',
  textAlign: 'right',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, -webkit-border-radius 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s',
  margin: '0px',
  marginBottom: '-4px',
  marginLeft: '-8px',
}));

const Avatar = styled('img')(() => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'none',
  boxShadow: 'none',
  borderRadius: '50px',
  objectFit: 'cover',
  height: '20px',
  width: '20px',
  minWidth: '20px',
  maxWidth: '20px',
  marginTop: '0px',
  minHeight: '20px',
  maxHeight: '20px',
}));

const PopoverContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 20px',
  transform: 'translateX(0px)',
  borderRadius: '22px',
  height: 'fit-content',
  width: '174px',
  transition: 'all 0s ease 0s',
  padding: '24px',
}));

const Label = styled('span')(() => ({
  fontFamily: '"Montserrat", sans-serif !important',
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: 500,
  letterSpacing: '-0.1px',
  color: 'rgb(150,150,150)',
  margin: '5px 14px',
}));

const PopoverAvatar = styled('img')(() => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'none',
  boxShadow: 'none',
  maxWidth: '100%',
  borderRadius: '50px',
  objectFit: 'cover',
  height: '40px',
  width: '40px',
}));

const UserName = styled('h2')(() => ({
  boxSizing: 'border-box',
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  fontSize: '12px',
  fontWeight: 600,
}));

const MenuContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  padding: '16px 0',
  borderStyle: 'solid',
  borderWidth: '1px 0px',
  borderColor: '#DBDBDB',
}));

const MenuItemText = styled('span')(() => ({
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  width: '100%',
  WebkitBoxPack: 'start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  fontSize: '11px',
  fontWeight: 600,
  color: '#424140',
  marginTop: '5px',
  lineHeight: '20px',
}));

const DefaultLabel = {
  logoutDialog: undefined,
};

export type ProfileIconButtonProps = {
  isMenu?: boolean;
  label?: string;
  alignLabel?: LabelIconButtonProps['alignLabel'];
  labels?: {
    logoutDialog?: LogoutDialogProps['labels'];
  };
  styles?: {
    avatarContainer: React.CSSProperties;
  };
  hideLabel?: boolean;
  closeOnClick?: () => void;
};

const ProfileIconButton = (props: ProfileIconButtonProps) => {
  const { setIsOpen, setStartWith } = useLoginModalStore();
  const {
    label,
    alignLabel,
    labels = DefaultLabel,
    styles,
    isMenu = false,
    hideLabel = false,
    closeOnClick,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [logout, setLogout] = React.useState(false);
  const [user, setUser] = React.useState<SessionUser>();
  const { user: sessionUser } = useSessionStore();
  const avatar = user?.avatar || defaultAvatar.src;

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // console.log('handleOpen!');
      // event.preventDefault();
      // event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLogout = React.useCallback(() => {
    setLogout(true);
  }, []);

  React.useEffect(() => {
    setUser(sessionUser);
  }, [sessionUser]);

  const isSmScreen = useMediaQuery('(max-width:1200px)');

  return (
    <>
      {/* Exibe quando não tem usuário logado */}
      <HiddenComponent hidden={!!user}>
        <LabelIconButton
          onClick={() => {
            setStartWith('login');
            setIsOpen(true);
            if (closeOnClick) {
              closeOnClick();
            }
          }}
          LinkComponent={Link}
          alignLabel={alignLabel}
          label={hideLabel ? '' : 'Entrar'}
          icon={<User />}
        />
      </HiddenComponent>

      {/* Exibe somente quando o usuário está logado */}
      <HiddenComponent hidden={!user}>
        <AvatarContainer
          onClick={(event) => {
            if (isSmScreen) return;
            handleOpen(event);
          }}
          onMouseOver={(event) => {
            if (isSmScreen) return;

            handleOpen(event);
          }}
          style={styles?.avatarContainer}
        >
          <Avatar height={20} width={20} src={avatar} />
          {isMenu && <Label>{user?.name}</Label>}
        </AvatarContainer>
      </HiddenComponent>
      <Menu anchorEl={anchorEl} onClose={onClose} onLogout={onLogout} />
      <LogoutDialog
        labels={labels?.logoutDialog}
        open={logout}
        close={() => setLogout(false)}
      />
    </>
  );
};

type MenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onLogout: () => void;
};

const Menu = (props: MenuProps) => {
  const { anchorEl, onClose, onLogout } = props;
  const [menu, user] = useUserMenu();
  const avatar = user?.avatar || defaultAvatar.src;

  const handleLogout = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onLogout();
    },
    [onLogout]
  );

  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        style: { borderRadius: '22px' },
        onMouseLeave: onClose,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <PopoverContainer
        style={{
          zIndex: 99999999,
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            position: 'relative',
            width: '100%',
            marginBottom: '10px',
            textAlign: 'center',
            margin: '12px 0px',
          }}
        >
          <PopoverAvatar height={40} width={40} src={avatar} />
        </div>
        <div
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <UserName>{user?.name}</UserName>
        </div>
        <MenuContainer>
          {menu.map((item, i) => (
            <MenuItem
              key={i}
              href={item.to}
              component={Link}
              style={{ padding: 0 }}
            >
              <MenuItemText>{item.title}</MenuItemText>
            </MenuItem>
          ))}
        </MenuContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <IconButton onClick={handleLogout}>
            <PowerSettingsNewRounded
              style={{ color: 'black', height: '20', width: '20' }}
            />
          </IconButton>
        </div>
      </PopoverContainer>
    </Popover>
  );
};

export default ProfileIconButton;
