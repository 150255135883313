import { styled } from '@mui/material/styles';
import AppTopBar from './AppTopBar';
import { NextSeo, type NextSeoProps, type ArticleJsonLdProps } from 'next-seo';
import OrganizationJsonLd from '@/lib/seo/OrganizationJsonLd';
import WebsiteJsonLd from '@/lib/seo/WebsiteJsonLd';
import JsonLdBuilder from '@/lib/seo/JsonLdBuilder';
import notification from '@bamboo/core-lib/components/Notification';
import alert from '@bamboo/core-lib/components/Alert';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { NavHeader } from './NavHeader/NavHeader';

const Root = styled('div')(({ theme }) => ({
  overflow: 'auto',
  overflowX: 'hidden',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 77px)',
  paddingTop: '77px',
}));

const Bar = styled('div')(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '0px 0px 1px',
  borderColor: 'rgb(236, 236, 236)',
  paddingTop: '75px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '45px',
  },
}));

const InfiniteLoading = styled('div')(() => ({
  '&:before': {
    content: "''",
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    zIndex: 2,
    background: '#ffffff',
    borderRadius: 8,
  },
  '&:after': {
    content: "''",
    height: '100vh',
    width: '100vw',
    borderRadius: 8,
    background: 'url(/assets/Infinity-0.9s-201px.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#ffffff',
    position: 'fixed',
    top: 0,
    zIndex: 2000,
    backgroundSize: '100px',
    opacity: '.44',
  },
}));

export const DefaultMetaTags = [
  {
    property: 'og:locale',
    content: 'pt_BR',
  },
  {
    property: 'og:type',
    content: 'article',
  },
  {
    property: 'og:site_name',
    content: 'BambooStock',
  },
  {
    property: 'article:publisher',
    content: 'https://facebook.com',
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image',
  },
];

const SnackbarNotification = notification();
const PopupAlert = alert();

export type PaperbaseProps = React.HTMLProps<HTMLDivElement> & {
  seoProps?: NextSeoProps;
  jsonLdProps?: ArticleJsonLdProps;
  disableNotification?: boolean;
  disablePassThroughLoading?: boolean;
};
let t: NodeJS.Timeout;
function Paperbase(props: PaperbaseProps) {
  const router = useRouter();
  const {
    seoProps,
    jsonLdProps,
    as,
    disableNotification,
    disablePassThroughLoading,
    ...others
  } = props;
  const [loading, setLoading] = useState(false);

  const seo: NextSeoProps | undefined = seoProps
    ? {
        ...seoProps,
        additionalMetaTags: [
          ...DefaultMetaTags,
          ...(seoProps?.additionalMetaTags ?? []),
        ],
      }
    : undefined;

  const startLoading = useCallback(() => {
    if (disablePassThroughLoading) {
      return;
    }
    clearTimeout(t);
    t = setTimeout(() => {
      setLoading(true);
    }, 500);
  }, [disablePassThroughLoading]);

  const endLoading = useCallback(() => {
    clearTimeout(t);
    setLoading(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', startLoading);
    router.events.on('routeChangeComplete', endLoading);
    router.events.on('routeChangeError', endLoading);
    return () => {
      router.events.off('routeChangeStart', startLoading);
      router.events.off('routeChangeComplete', endLoading);
      router.events.off('routeChangeError', endLoading);
    };
  }, [endLoading, router, startLoading]);

  return (
    <>
      <NextSeo
        {...seo}
        noindex={process.env.NEXT_PUBLIC_ENVIRONMENT == 'homolog'}
        nofollow={process.env.NEXT_PUBLIC_ENVIRONMENT == 'homolog'}
      />
      <OrganizationJsonLd />
      <WebsiteJsonLd />
      <NavHeader />
      {/* <Bar /> */}
      <Root {...others} />
      <PopupAlert />
      {disableNotification ? null : <SnackbarNotification />}
      {loading ? <InfiniteLoading /> : null}
      <JsonLdBuilder />
    </>
  );
}

export default Paperbase;
