import { create } from 'zustand';

type CartIconButtonStore = {
  hasNewItem: boolean;
  toggleHasNewItem: (newValue?: boolean) => void;
};

export const useCartIconButtonStore = create<CartIconButtonStore>((set) => ({
  hasNewItem: false,
  toggleHasNewItem: (newValue) =>
    set((state) => ({
      hasNewItem: newValue !== undefined ? newValue : !state.hasNewItem,
    })),
}));
