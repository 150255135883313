import type { FC } from 'react';
import React, { use, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { authConfig } from '@/features/auth/auth.config';
import { useInlineNotification } from '@bamboo/core-lib/components/Notification';
import { useRouter } from 'next/router';
import { AppPaperbase } from '@/components/layout';
import WebPageJsonLd from '@/lib/seo/WebPageJsonLd';
import BreadCrumbListJsonLd from '@/lib/seo/BreadCrumbListJsonLd';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import { setRouterStore, useRouterStore } from '@bamboo/core-lib/repo/router';
import { LoginModal } from '../components/loginModal';
import { useLoginModalStore } from '../components/loginModal/store';

export enum SubmittedType {
  login = 'login',
  onboarding = 'onboarding',
}

export const LoginPage: FC = () => {
  const { t } = useTranslation(authConfig.i18nNamespaces);
  const { setIsOpen, setStartWith } = useLoginModalStore();

  const router = useRouter();

  const pageURL = `${process.env.ECOMMERCE_URL}${router.asPath}`;
  const breadCrumbs = [
    { title: t('auth:bread_crumbs.home'), link: '/' },
    { title: t('auth:bread_crumbs.toEnter') },
  ];
  const [_, notifySocial] = useInlineNotification();
  const { jwt } = useSessionStore();
  const { routerStore } = useRouterStore();

  useEffect(() => {
    setIsOpen(false);
    setStartWith('login');
  }, []);

  const atMount = useCallback(() => {
    if (!router.isReady) return;

    if (((router.query.redirect as string)?.trim?.() ?? '').length > 0) {
      setRouterStore({ redirect: router.query.redirect });
    }

    if (((router.query.oauthError as string)?.trim?.() ?? '').length > 0) {
      notifySocial({
        message: `${t('auth:error.social_login_error')}: ${
          router.query.oauthError ?? ''
        }`,
        type: 'error',
        temporary: true,
      });
      return;
    }

    let redirect;
    if (routerStore) {
      redirect = routerStore.redirect as string;
    }

    redirect = redirect ?? (router.query['redirect'] as string);

    if (jwt) {
      router.push(redirect ?? '/search');
      return;
    }

    if (((router.query.token as string)?.trim?.() ?? '').length > 0) {
      router.push(redirect ?? '/search');
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    atMount();
  }, [atMount]);

  return (
    <AppPaperbase
      disableNotification
      seoProps={{
        title: t('auth:page.signin_title'),
        additionalMetaTags: [
          {
            property: 'og:description',
            content: t('common:seo.description'),
          },
          {
            property: 'og:url',
            content: pageURL,
          },
          {
            property: 'og:image',
            content: `${process.env.ECOMMERCE_URL}/images/seoImage.jpg`,
          },
          {
            property: 'og:image:width',
            content: '1554',
          },
          {
            property: 'og:image:height',
            content: '1024',
          },
        ],
      }}
    >
      <WebPageJsonLd
        url={pageURL}
        id={`${pageURL}/#webpage`}
        primaryImageOfPage={{ '@id': `${pageURL}/#primaryimage` }}
        breadcrumb={{ '@id': `${pageURL}/#breadcrumb` }}
        name={t('auth:page.signin_title')}
        potentialAction={[
          {
            '@type': 'ReadAction',
            target: [pageURL],
          },
        ]}
      />
      <BreadCrumbListJsonLd
        id={`${pageURL}/#breadcrumb`}
        itemListElement={breadCrumbs.map((crumb, index) => ({
          '@type': 'ListItem',
          position: index,
          name: crumb.title,
          item: crumb?.link
            ? `${process.env.ECOMMERCE_URL}${crumb?.link}`
            : undefined,
        }))}
      />
      <LoginModal isLoginPage={true} />
    </AppPaperbase>
  );
};
