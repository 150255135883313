import React from 'react';
import { api, adminApi } from '@bamboo/core-lib/src/api';
import type {
  ClipsListParams,
  Clip,
  ClipsGetParamsV2,
} from '@bamboo/core-lib/src/api/types';
import { useSessionStore } from '@bamboo/core-lib/src/repo/session';
import {
  useClips,
  setClips,
  setClipCategoriesV2,
} from '@bamboo/core-lib/src/repo/clips';
import { getVideoDownloadURL } from '@bamboo/core-lib/src/api/flows/media';
import {
  useLocalConfig,
  SettingsKeys,
} from '@bamboo/ui-lib/src/hooks/localConfig';
import { serialize } from '@bamboo/ts-utils';
import fileDownload from 'js-file-download';
import axios, { AxiosProgressEvent } from 'axios';
import { isAfter } from 'date-fns';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

export const useListFiltredClips = (initialValue?: { loading?: boolean }) => {
  const [loading, setLoading] = React.useState(initialValue?.loading ?? false);
  const { clipBySlug, clipsListed } = useClips();
  const clips = clipsListed
    .map((slug) => clipBySlug[slug])
    .filter((c) => !!c) as Clip[];

  const fetchClips = React.useCallback(
    (param?: ClipsListParams, cb?: (c: Clip[]) => Clip[]) => {
      setLoading(true);
      return api
        .clipsListClips({
          ...param,
          paramsSerializer: { serialize: (p) => serialize(p) },
        })
        .then((res) => {
          const resp = cb ? cb(res.data.items) : res.data.items;
          setClips(resp);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch(console.warn);
    },
    []
  );

  return [loading, clips, fetchClips] as [
    typeof loading,
    typeof clips,
    typeof fetchClips
  ];
};
export const useListFiltredClipsV2 = (initialValue?: { loading?: boolean }) => {
  const [loading, setLoading] = React.useState(initialValue?.loading ?? false);
  const { clipBySlug, clipsListed } = useClips();
  const clips = clipsListed
    .map((slug) => clipBySlug[slug])
    .filter((c) => !!c) as Clip[];

  const fetchClips = React.useCallback(
    (param?: ClipsListParams, cb?: (c: Clip[]) => Clip[]) => {
      setLoading(true);
      return api
        .clipsListClipsV2({
          ...param,
          paramsSerializer: { serialize: (p) => serialize(p) },
        })
        .then((res) => {
          const resp = cb ? cb(res.data.items) : res.data.items;
          setClips(resp);
          setClipCategoriesV2(res.data.categories);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch(console.warn);
    },
    []
  );

  return [loading, clips, fetchClips] as [
    typeof loading,
    typeof clips,
    typeof fetchClips
  ];
};

export const useGetClipWithRecommendedV2 = (initialValue?: {
  loading?: boolean;
}) => {
  const [loading, setLoading] = React.useState(initialValue?.loading ?? false);
  const { clipBySlug, clipsListed } = useClips();
  const clips = clipsListed
    .map((slug) => clipBySlug[slug])
    .filter((c) => !!c) as Clip[];

  const fetchClips = React.useCallback(
    (param?: ClipsGetParamsV2, cb?: (c: Clip[]) => Clip[]) => {
      setLoading(true);
      return api
        .clipsListRecommendedClips({
          ...param,
          paramsSerializer: { serialize: (p) => serialize(p) },
        })
        .then((res) => {
          const resp = cb ? cb(res.data.items) : res.data.items;
          setClips(resp);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch(console.warn);
    },
    []
  );

  return [loading, clips, fetchClips] as [
    typeof loading,
    typeof clips,
    typeof fetchClips
  ];
};

export const useAdminListFiltredClips = (initialValue?: {
  loading?: boolean;
}) => {
  const [loading, setLoading] = React.useState(initialValue?.loading ?? false);
  const { clipBySlug, clipsListed } = useClips();
  const clips = clipsListed
    .map((slug) => clipBySlug[slug])
    .filter((c) => !!c) as Clip[];

  const fetchClips = React.useCallback(
    (param?: ClipsListParams, cb?: (c: Clip[]) => Clip[]) => {
      setLoading(true);
      adminApi
        .clipsListClips({
          ...param,
          paramsSerializer: { serialize: (p) => serialize(p) },
        })
        .then((res) => {
          const resp = cb ? cb(res.data.items) : res.data.items;
          setClips(resp);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch(console.warn);
    },
    []
  );

  return [loading, clips, fetchClips] as [
    typeof loading,
    typeof clips,
    typeof fetchClips
  ];
};

function axiosDownload(
  url: string,
  name: string,
  cb?: (e: AxiosProgressEvent) => void
) {
  return axios
    .get(url, {
      responseType: 'blob',
      onDownloadProgress: cb,
    })
    .then((resp) => fileDownload(resp.data, name));
}

const FilePrefix = 'BAMBOOSTOCK_';
export const useDownloadWatermark = () => {
  const updateAnalytics = React.useCallback(async (url: string) => {
    const fingerprint = await getCurrentBrowserFingerPrint();
    if (fingerprint) {
      api.analyticsPageview({
        path: url,
        fingerprint: String(fingerprint),
      });
    }
  }, []);

  const onDownload = React.useCallback(
    (url: string, sku: string) => {
      if (!url.trim()) {
        return Promise.reject('url not found');
      }
      const extension = url.split('.').pop()?.toLowerCase();
      return axiosDownload(url, `${FilePrefix}${sku}.${extension}`).then(() => {
        updateAnalytics(url);
      });
    },
    [updateAnalytics]
  );

  return [onDownload] as [typeof onDownload];
};

export const useDownloadVideo = () => {
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [value, setLocalConfig] = useLocalConfig(SettingsKeys.DownloadVideoUrl);
  const { jwt } = useSessionStore();

  const progressMonitor = React.useCallback((e: AxiosProgressEvent) => {
    setProgress((e.loaded! / e.total!) * 100);
  }, []);

  const onDownload = React.useCallback(
    (usdoID: number, url: string, sku: string) => {
      if (!url.trim()) {
        return Promise.reject();
      }
      setLoading(true);
      const extension = url.split('.').pop()?.toLowerCase();
      const fileName = `${FilePrefix}${sku}.${extension}`;
      let currentURL = value?.[usdoID]?.url;
      const expire = value?.[usdoID]?.expiredAt;
      if (!!expire && isAfter(new Date(), expire)) {
        currentURL = undefined;
      }

      if (!!currentURL) {
        return axiosDownload(currentURL, fileName).finally(() =>
          setLoading(false)
        );
      }

      return getVideoDownloadURL(`Bearer ${jwt}`, url, usdoID)
        .then(async (res) => {
          const expiredAt = new Date(res.expiresAt);
          await axiosDownload(res.url, fileName, progressMonitor).catch((e) => {
            setLocalConfig({ [usdoID]: { url: res.url, expiredAt } });
            throw e;
          });
        })
        .finally(() => setLoading(false));
    },
    [jwt, value, setLocalConfig, progressMonitor]
  );

  return [loading, onDownload, progress] as [
    typeof loading,
    typeof onDownload,
    typeof progress
  ];
};
