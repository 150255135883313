import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Zoom } from '@mui/material';
import type { ButtonProps } from '@mui/material';
import CircularProgressWithLabel from '@bamboo/core-lib/src/components/Display/CircularProgressWithLabel';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import { useDownloadVideo } from '@bamboo/core-lib/hooks/clips';
import { notify } from '@bamboo/core-lib/repo/notification';

const StyledButton = styled(Button)(() => ({
  backgroundColor: 'rgb(25, 186, 187)',
  borderRadius: '60px',
  marginTop: '16px',
  textTransform: 'none',
  color: '#ffff',
  '&: hover': {
    backgroundColor: 'rgb(25, 186, 187)',
  },
  '&: disabled': {
    color: 'white',
    backgroundColor: '#9d9494',
  },
}));

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px',
  borderRadius: '50%',
  textTransform: 'none',
  color: '#ffff',
  backgroundColor: 'rgb(25, 186, 187)',
}));

export type VideoDownloadButtonProps = {
  downloadUrl: string;
  usdoID: number;
  sku: string;
  children?: React.ReactNode;
  labels?: {
    genericFailure?: string;
    maxLimitReached?: string;
  };
} & Omit<ButtonProps, 'onClick'>;

const VideoDownloadButton = (props: VideoDownloadButtonProps) => {
  const { children, labels, downloadUrl, usdoID, sku, disabled, ...others } =
    props;
  const [loading, onDownload, progress] = useDownloadVideo();

  const handleDownload = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!!downloadUrl && !!usdoID) {
        onDownload(usdoID, downloadUrl, sku).catch((e) => {
          console.warn(e);
          const err = e?.response?.data?.error?.message ?? e?.message ?? e;
          let message = labels?.genericFailure ?? err;
          if (err?.indexOf?.('download limit reached') !== -1) {
            message = labels?.maxLimitReached ?? '';
          }
          notify({
            message: message,
            type: 'error',
            temporary: true,
          });
        });
      }
    },
    [labels, downloadUrl, usdoID, sku]
  );

  return (
    <>
      <Zoom in={!loading}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <HiddenComponent hidden={loading}>
            <StyledButton
              onClick={handleDownload}
              disabled={loading || disabled}
              {...others}
            >
              {children}
            </StyledButton>
          </HiddenComponent>
        </div>
      </Zoom>
      <Zoom in={loading}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <HiddenComponent hidden={!loading}>
            <LoadingContainer>
              <CircularProgressWithLabel
                color="inherit"
                size={40}
                progress={progress}
                labelProps={{ style: { fontWeight: 'bold' } }}
              />
            </LoadingContainer>
          </HiddenComponent>
        </div>
      </Zoom>
    </>
  );
};

export default VideoDownloadButton;
