import Excel from './custom/Excel';
import CloseIcon from './custom/CloseIcon';
import CartIcon from './custom/CartIcon';
import Paper from './custom/Paper';

export default {
  CloseIcon,
  CartIcon,
  Excel,
  Paper,
};
