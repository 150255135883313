import { StyledButton, StyledRegisterButton } from './LogInButton.styles';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import { AccountCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { homeConfig } from '@/features/home/home.config';
import ProfileIconButton from '@/components/layout/ProfileIconButton';
import FavoriteIconButton from '@/components/layout/FavoriteIconButton';
import CartIconButton from '@/components/layout/CartIconButton';
import { useLoginModalStore } from '@/features/auth/components/loginModal/store';

type TProps = {
  isHomePage?: boolean;
};

export const LogInButton = ({ isHomePage = false }: TProps) => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { jwt } = useSessionStore();
  const { setIsOpen, setStartWith } = useLoginModalStore();

  function handleClick(type: 'login' | 'register') {
    if (type === 'login') {
      setStartWith('login');
    }

    if (type === 'register') {
      setStartWith('register');
    }

    setIsOpen(true);
  }

  const isLoggedIn = jwt;

  if (!isHomePage)
    return (
      <>
        <ProfileIconButton
          label={t('common:actions.toEnter')}
          alignLabel="left"
          hideLabel
          labels={{
            logoutDialog: {
              accept: t('common:actions.yes'),
              refuse: t('common:actions.no'),
              text: t('common:logoutText'),
            },
          }}
          styles={{
            avatarContainer: {
              marginBottom: '0px',
            },
          }}
        />
        <FavoriteIconButton
          dialogLabels={{
            close: t('common:actions.close'),
            loginText: t('navigation:authForm.title'),
            onboardingDescription: t(
              'navigation:authForm.onboarding_description'
            ),
            generic_login_error: t('navigation:authForm.generic_login_error'),
            user_already_exists: t('navigation:authForm.user_already_exists'),
            generic_onboarding_error: t(
              'navigation:authForm.generic_onboarding_error'
            ),
            user_doesnt_exist: t('navigation:authForm.user_doesnt_exist'),

            noAccount: t('navigation:authForm.no_account'),
            hasAccount: t('navigation:authForm.has_account'),
            create_account: t('navigation:authForm.create_account'),
            onboardingText: t('navigation:authForm.onboarding_title'),
            loginDescription: t('navigation:authForm.description'),
            loginFormLabels: {
              email_username: t('navigation:authForm.email_username'),
              remember_me: t('navigation:authForm.remember_me'),
              forgot_password: t('navigation:authForm.forgot_password'),
              signin_submit: t('navigation:authForm.signin_submit'),
              password: t('navigation:authForm.password'),
            },
            onboardingFormLabels: {
              email: t('navigation:authForm.email_username'),
              password: t('navigation:authForm.password'),
              password_confirm: t(
                'navigation:authForm.onboarding_confirm_password'
              ),
              user_first_name: t('navigation:authForm.onboarding_user_name'),
              user_last_name: t('navigation:authForm.onboarding_user_name'),
              reading_terms: t('navigation:authForm.onboarding_reading_terms'),
              terms: t('navigation:authForm.onboarding_terms'),
              onboarding_submit: t('navigation:authForm.onboarding_submit'),
              password_error: t(
                'navigation:authForm.onboarding_password_error'
              ),
              email_error: t('navigation:authForm.onboarding_email_error'),
            },
            social_continue_with: t('auth:social.continue_with'),
            signing_method_or: t('auth:form.signing_method_or'),
          }}
        />
        <CartIconButton
          labels={{
            cartItens: (n) => t('common:cart.cartItens', { n }),
            checkout: t('common:actions.checkout'),
            total: t('common:cart.total'),
            emptyCart: t('common:cart.emptyCart'),
            search: t('common:cart.search'),
            itemRemovedFromCart: t('common:cart.itemRemovedFromCart'),
            failedToRemoveItemFromCart: t(
              'common:cart.failedToRemoveItemFromCart'
            ),
          }}
        />
      </>
    );

  return (
    <>
      {!isLoggedIn && (
        <>
          <StyledButton onClick={() => handleClick('login')} variant="text">
            Entrar
          </StyledButton>

          <StyledRegisterButton
            onClick={() => handleClick('register')}
            variant="contained"
          >
            <p>Crie sua conta</p>
            <AccountCircle />
          </StyledRegisterButton>
        </>
      )}
      {isLoggedIn && (
        <>
          <ProfileIconButton
            label={t('common:actions.toEnter')}
            alignLabel="left"
            labels={{
              logoutDialog: {
                accept: t('common:actions.yes'),
                refuse: t('common:actions.no'),
                text: t('common:logoutText'),
              },
            }}
            styles={{
              avatarContainer: {
                marginBottom: '0px',
              },
            }}
          />
          <FavoriteIconButton
            dialogLabels={{
              close: t('common:actions.close'),
              loginText: t('navigation:authForm.title'),
              onboardingDescription: t(
                'navigation:authForm.onboarding_description'
              ),
              generic_login_error: t('navigation:authForm.generic_login_error'),
              user_already_exists: t('navigation:authForm.user_already_exists'),
              generic_onboarding_error: t(
                'navigation:authForm.generic_onboarding_error'
              ),
              user_doesnt_exist: t('navigation:authForm.user_doesnt_exist'),

              noAccount: t('navigation:authForm.no_account'),
              hasAccount: t('navigation:authForm.has_account'),
              create_account: t('navigation:authForm.create_account'),
              onboardingText: t('navigation:authForm.onboarding_title'),
              loginDescription: t('navigation:authForm.description'),
              loginFormLabels: {
                email_username: t('navigation:authForm.email_username'),
                remember_me: t('navigation:authForm.remember_me'),
                forgot_password: t('navigation:authForm.forgot_password'),
                signin_submit: t('navigation:authForm.signin_submit'),
                password: t('navigation:authForm.password'),
              },
              onboardingFormLabels: {
                email: t('navigation:authForm.email_username'),
                password: t('navigation:authForm.password'),
                password_confirm: t(
                  'navigation:authForm.onboarding_confirm_password'
                ),
                user_first_name: t('navigation:authForm.onboarding_user_name'),
                user_last_name: t('navigation:authForm.onboarding_user_name'),
                reading_terms: t(
                  'navigation:authForm.onboarding_reading_terms'
                ),
                terms: t('navigation:authForm.onboarding_terms'),
                onboarding_submit: t('navigation:authForm.onboarding_submit'),
                password_error: t(
                  'navigation:authForm.onboarding_password_error'
                ),
                email_error: t('navigation:authForm.onboarding_email_error'),
              },
              social_continue_with: t('auth:social.continue_with'),
              signing_method_or: t('auth:form.signing_method_or'),
            }}
          />
          <CartIconButton
            labels={{
              cartItens: (n) => t('common:cart.cartItens', { n }),
              checkout: t('common:actions.checkout'),
              total: t('common:cart.total'),
              emptyCart: t('common:cart.emptyCart'),
              search: t('common:cart.search'),
              itemRemovedFromCart: t('common:cart.itemRemovedFromCart'),
              failedToRemoveItemFromCart: t(
                'common:cart.failedToRemoveItemFromCart'
              ),
            }}
          />
        </>
      )}
    </>
  );
};
